@import '../../../styles/customMediaQueries.css';


input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  /* justify-content: space-between; */
  /* margin-top: 24px; */
  flex-direction: column;
  gap: 15px;

  @media (--viewportMedium) {
    /* margin-top: 32px; */
  }
}

.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  /* width: calc(34% - 9px); */
}

.lastNameRoot {
  /* width: calc(66% - 9px); */
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.eyeIcon {
  float: right;
  margin-top: 75px;
  margin-right: 75px;
  position: absolute;
  z-index: 9;
  right: 0;

  @media (max-width: 768px) {
    margin-top: 55px;
    margin-right: 40px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.boxContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  flex-direction: column;
  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.previousButton {
  border: 0px;
  width: 110px;
  border-radius: 5px;
  background: #3a5795;
  color: #FFF;
  font-weight: normal;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.userBox {
  border: 1px;
  border-radius: 5px;
}

.joinText {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: left;
  margin-bottom: 25px;
}
.joinUs{
  text-align: center;
  font-weight: 600;
  color: var(--marketplaceColor);
}
.registerCard{
  box-shadow:rgba(149, 157, 165, 0.2) 0 8px 24px;
  border-radius: 12px;
  overflow: hidden;
  flex-basis: 33%;
  cursor: pointer;

  & p{
    padding: 15px 10px;
    text-align: center;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.86;
    color: #696969;
  }
  
}
.registerCard img{
  width: 329px;
}
.userBox{
  & button{
    padding: 16px !important;
  }
}
.headingLoginBox{
  font-weight: 600;
  font-size: 20px;
}
.passwordCap{
  display: flex;
  gap: 5px;
  margin-top: 10px;

& div{
  background: rgb(4, 113, 18);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #FFF;
 display: flex;
 justify-content: center;
 align-items: center;
}
}
