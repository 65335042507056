@import '../../../styles/customMediaQueries.css';


input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}


.eyeIcon {
  float: right;
  margin-top: 75px;
  margin-right: 75px;
  position: absolute;
  z-index: 9;
  right: 0;

  @media (max-width: 768px) {
    margin-top: 55px;
    margin-right: 40px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}
